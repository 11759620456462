import React from "react";
import { Link } from 'react-router-dom';
import { Header, } from '../Components';




function Home() {

    return (
      <React.Fragment>
        <Header />
          <h6 className="fs-3 mb-5">Here you can chose what to do!</h6>
          <div className="mr-5 mb-5">
            <Link to="/video/update">
              <button className="btn btn-primary">Update Video</button>
            </Link>
          </div>
          <div className="mr-5 mb-5">
            <Link to="/timestamps/create">
              <button className="btn btn-primary">Add Timestamps</button>
            </Link>
          </div>
          <div className="mr-5 mb-5">
            <Link to="/timestamps/edit">
              <button className="btn btn-primary">Edit Timestamps</button>
            </Link>
          </div>
          <div className="mr-5 mb-5">
            <Link to="/category/create">
              <button className="btn btn-primary">Edit Categories</button>
            </Link>
          </div>
          <div className="mr-5 mb-5">
            <Link to="/entry/create">
              <button className="btn btn-primary">Create Entry</button>
            </Link>
          </div>
          <div className="mr-5 mb-5">
            <Link to="/entry/edit">
              <button className="btn btn-primary">Edit Entry</button>
            </Link>
          </div>
      </React.Fragment>
    );
}
export default Home;