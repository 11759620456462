import React, { useState, useRef, useContext, useEffect } from "react";
import { collection, addDoc, onSnapshot, setDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import  { db } from './../firebase';
import { v4 } from 'uuid';
import { Header } from '../Components';


function CreateEntry() {

  const inputWidth = {
    width: "30rem"
  }

  const desWidth = {
    width: "40rem",
  }

  const [categoryList, setCategoryList] = useState([]);
  const [selects, setSelects] = useState("");

  const titleRef = useRef();
  const articleRef = useRef();
  const publicationRef = useRef();
  const dateRef = useRef();
  const descriptionRef = useRef();

  //we need to determine how to connect entrys to categories
  useEffect(() => {
    const unSubscribe = onSnapshot(

      collection(db, 'category'),
      (collectionSnapshot) => {
        const category = [];
        collectionSnapshot.forEach((doc) => {
          category.push({
            title: doc.data().title,
            id: doc.data().id,
          });
        });
        setCategoryList(category);
      },
      (error) => {

      }
    );
    return () => unSubscribe();
  },[])

  const onNewEntryCreation=(e)=>{
    e.preventDefault();
    setDoc(doc(db,'entry',titleRef.current.value ),{
      title: titleRef.current.value,
      link: articleRef.current.value,
      publishDate: new Date(e.target.publishDate.value),
      description: descriptionRef.current.value,
      categoryId: e.target.category.value,
      publication: publicationRef.current.value,
      entryDate: new Date(),
      id: v4()
    });
    
    document.getElementById("entryForm").reset();
  }
  
  console.log(categoryList);


  return (
    <React.Fragment>
      <Header />
    {categoryList.length > 0 ? (
      <div className="">
        <form id="entryForm" className="card border-0 mt-5" onSubmit={onNewEntryCreation}>
          <div className="">
            <input className="form-control" style={inputWidth} type="text" id="title" placeholder="Title" ref={titleRef}/>
            <input className="form-control mt-5" style={inputWidth} type="text" id="link" placeholder="Link" ref={articleRef}/>
            <input className="form-control mt-5" style={inputWidth} type="text" id="publication" placeholder="Publication" ref={publicationRef}/>
            <input className="form-control mt-5" style={inputWidth} type="date" id="publishDate" placeholder="Publish Date" ref={dateRef}/>
            <textarea className="form-control mt-5" style={desWidth} type="textarea" rows="4" id="description" placeholder="Description" ref={descriptionRef}/>
            <div className="mt-5">
              <select value={selects} onChange={e => setSelects(e.target.value)} name="category">
                {Object.values(categoryList).map((category) => (
                  <option value={category.title}>{category.title}</option>
                ))}
              </select>
            </div>
            <button className="btn btn-primary mt-5" type='submit'>Create entry</button>
          </div>
        </form>
      </div>
    ): (
      <h1>Please create a category </h1>
    )}
    </React.Fragment>
    
  )
}

export default CreateEntry;

// db.Timestamp.fromDate() for date to timestamp convert;