import React from "react";
import { CreateEntry, CreateCategory, EditEntry, Home, Login, ModifyTimestamps, EditVideo, EditTimeStamps, PrivateRoute } from './Components';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import "bootstrap/dist/js/bootstrap.min.js";

function App() {

  

  return (
    <Router>
      
      <div className="bg-slate-100 h-screen w-screen">
        <Routes>
          {/* these will be private routes only accessible to logged in users */}
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Home/>} />

            <Route path="/category/create" element={<CreateCategory />} />
            {/* <Route path="/category/edit" element={<EditCategory />} /> */}
            <Route path="/entry/create" element={<CreateEntry />} />
            <Route path="/video/update" element={<EditVideo />} />
            <Route path="/timestamps/create" element={<EditTimeStamps />} />
            <Route path="/timestamps/edit" element={<ModifyTimestamps />} />
            <Route path="/entry/edit" element={<EditEntry /> }  />
          </Route>
          {/* these routes will be accessible if not signed in */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;
