import React, { useState, useRef, useEffect } from "react";
import { collection, addDoc, onSnapshot, } from 'firebase/firestore';
import  { db } from './../firebase';
import { v4 } from 'uuid';
import { Header } from '../Components';

function EditTimeStamps() {

  const inputWidth = {
    width: "30rem"
  }

  const desWidth = {
    width: "40rem",
  }

  const [videoList, setVideoList] = useState([])

  const timeRef = useRef();
  const descriptionRef = useRef();

  //we need to determine how to connect entrys to categories
  useEffect(() => {
    const unSubscribe = onSnapshot(

      collection(db, 'videos'),
      (collectionSnapshot) => {
        const video = [];
        collectionSnapshot.forEach((doc) => {
          video.push({
            title: doc.data().title,
            id: doc.id,
          });
        });
        setVideoList(video);
      },
      (error) => {

      }
    );
    return () => unSubscribe();
  },[])

  const onNewEntryCreation=(e)=>{
    e.preventDefault();
    addDoc(collection(db,'timestamps'),{
      time: timeRef.current.value,
      description: descriptionRef.current.value,
      videoId: e.target.category.value,
      entryDate: new Date(),
      id: v4()
    });
    
    document.getElementById("entryForm").reset();
  }



  return (
    <React.Fragment>
      <Header />
      <h1 className="mt-5">Add Timestamps</h1>
    {videoList.length > 0 ? (
      <div className="">
        <form id="entryForm" className="card border-0" onSubmit={onNewEntryCreation}>
          <div className="">
            <input className="form-control mt-5" style={inputWidth} type="text" id="time" placeholder="Time (hh:mm:ss)" ref={timeRef}/>
            <textarea className="form-control mt-5" style={desWidth} type="textarea" rows="4" id="description" placeholder="Description" ref={descriptionRef}/>
            <div className="mt-5">
              <select className="" name="category">
                {Object.values(videoList).map((category) => (
                  <option value={category.id}>{category.title}</option>
                ))}
              </select>
            </div>
            <button className="btn btn-primary mt-5" type='submit'>Create entry</button>
          </div>
        </form>
      </div>
    ): (
      <h1>Please Upload a video </h1>
    )}
    </React.Fragment>
    
  )
}

export default EditTimeStamps;