import React, { useState, useEffect } from "react";
import { collection, doc, onSnapshot, deleteDoc, query, where, getDocs  } from 'firebase/firestore';
import { Header } from '../Components';
import  { db } from '../firebase';

function ModifyTimestamps() {

  const [lisaArray, setLisaArray] = useState(null);
  const [entryLoaded, setEntryLoaded] = useState(false);

  //gets entries from db
  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, "timestamps"),
      (collectionSnapshot) => {
        const categories = [];
        collectionSnapshot.forEach((doc) => {
          categories.push({
            description: doc.data().description,
            time: doc.data().time,
            entryDate: doc.data().entryDate,
            videoId: doc.data().videoId,
            id: doc.data().id
          });
        });
        setLisaArray(categories);
        setEntryLoaded(true);
      },
    );
    return () => unSubscribe();
  }, []);

  async function homer(stampId) {
    await deleteDoc(doc(db, "timestamps", stampId));
  }

  //deletes doc
  const handleDeletingEntry = async (category) => {
    console.log("delete");
    const citiesRef = collection(db, "timestamps");
    const q = query(citiesRef, where("id", "==", category));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      homer(doc.id);
    })  
  }

  let test;       
  if (lisaArray) {
    const bart = lisaArray.sort((a, b) => a.videoId.localeCompare(b.videoId));
    test = <>
            {Object.values(bart).map((category) => (
            <>
              <span><p value={category.id}><strong>{category.videoId}</strong> - {category.description}  <button onClick={() => handleDeletingEntry(category.id)}>Delete</button></p> </span>
            </>
          ))}        
          </>
        }

  return (
    <React.Fragment>
      <Header />
      <div className="mt-5">
        {entryLoaded ? test : null}
      </div>   
    </React.Fragment>
  );
}

export default ModifyTimestamps;