import React, { useState, useContext, useCallback } from "react";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, onAuthStateChanged } from "firebase/auth";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


function Login(props) {

  const [signInSuccess, setSignInSuccess] = useState(null);
  const [authStat, setAuthstat] = useState(false);

  const navigate = useNavigate();
  const toLogin = useCallback(() => navigate('/', {replace:true}, [navigate]));
  
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAuthstat(true)
    } else {
      setAuthstat(false);
    }
  });

  function doSignIn(event) {
    event.preventDefault();
    const email = event.target.signinEmail.value;
    const password = event.target.signinPassword.value;

    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
      .then(() => {

    return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      setSignInSuccess(`You've successfully signed in as ${userCredential.user.email}!`);
      {toLogin()};

      })
      .catch((error) => {
        setSignInSuccess(`There was an error signing in: ${error.message}!`);
      });
    })
  }

  const cardWidth = {
    width: "18rem",
    marginTop: "250px",
  }
  console.log(props);
  return (
      <React.Fragment>
        <div className="h-screen text-center mx-auto p-3 " style={cardWidth}>
          <h1>Grease Log in</h1>
        <form onSubmit={doSignIn}>
          <input
            className="form-control mt-2"
            type='text'
            name='signinEmail'
            placeholder='email' />
          <input
            className="form-control mt-2"
            type='password'
            name='signinPassword'
            placeholder='Password' />
          <button className="btn btn-primary mt-5" type='submit'>Sign in</button>
        </form>
      </div>
      </React.Fragment>
  )
}

export default Login;

Login.propTypes = {
  signInMethod : PropTypes.func,
}