import React from "react";
import { Link } from 'react-router-dom';
import { auth } from "./../firebase";
import { signOut } from "firebase/auth";

function Header() {

  function doSignOut() {
    signOut(auth)
      .then(function() {
       
      }).catch(function(error) {
       console.log(error);
      });
  }

  return (
    <React.Fragment>
      <div className="d-flex mt-5 gap-5 ">
        <Link to="/"><h1>Anaheim Grease Admin Page</h1></Link>
        <button className="btn btn-primary" onClick={doSignOut}>Sign out</button>
      </div>
    </React.Fragment>
  )
}

export default Header;