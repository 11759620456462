import React, { useState, useRef, useContext, useEffect } from "react";
import { collection, setDoc, doc, addDoc, onSnapshot } from 'firebase/firestore';
import { Header } from '../Components';
import  { db } from '../firebase';
import { v4 } from 'uuid';

function CreateCategory() {

  const categoryRef = useRef();
  const [categoryArray, setCategoryArray] = useState([]);

  //sets doc in database and clears field
  const onNewCategoryCreation = async (e)=> {
    e.preventDefault();

    setDoc(doc(db,'category', categoryRef.current.value),{
      title: categoryRef.current.value,
      id: v4()
    })
    document.getElementById("catForm").reset();
  }

    //pulls current categories from database
    useEffect(() => {
      const unSubscribe = onSnapshot(
        collection(db, "category"),
        (collectionSnapshot) => {
          const categories = [];
          collectionSnapshot.forEach((doc) => {
            categories.push({
              title: doc.data().title,
              id: doc.data().id
            });
          });
          setCategoryArray(categories);
        },
      );
      return () => unSubscribe();
    }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="container mt-5">
        <form id="catForm" className="row" onSubmit={onNewCategoryCreation}>
          <div className="col">
            <input className="form-control " type="text" id="categoryTitle" placeholder="Category Title" ref={categoryRef}/>
          </div>
          <div className="col">
            <button className="btn btn-primary" type='submit'>Create Cateogry</button>
          </div>
        </form>
        <div>
          <div className="mt-5">
            <h3>Current Categories:</h3>
          </div>
          {categoryArray.map(({title}) => {
            return (
              <h5>{title}</h5>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateCategory;