import React, { useState, useRef, useContext, useEffect } from "react";
import { collection, setDoc, doc, addDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { Header } from '../Components';
import  { db } from '../firebase';
import { v4 } from 'uuid';


function EditEntry() {

  const [lisaArray, setLisaArray] = useState(null);
  const [entryLoaded, setEntryLoaded] = useState(false);

  //gets entries from db
  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, "entry"),
      (collectionSnapshot) => {
        const categories = [];
        collectionSnapshot.forEach((doc) => {
          categories.push({
            title: doc.data().title,
            categoryId: doc.data().categoryId,
            description: doc.data().description,
            publication: doc.data().publication,
            publicationDate: doc.data().publicationDate,
            link: doc.data().link,
            id: doc.data().id
          });
        });
        setLisaArray(categories);
        setEntryLoaded(true);
      },
    );
    return () => unSubscribe();
  }, []);

  //deletes doc
  const handleDeletingEntry = async (category) => {
    console.log("delete");
    await deleteDoc(doc(db, "entry", category));
  }

  let test;       
  if (lisaArray) {
    const bart = lisaArray.sort((a, b) => a.categoryId.localeCompare(b.categoryId));
    test = <>
            {Object.values(bart).map((category) => (
            <>
              <span><p value={category.id}>{category.title} - <strong>{category.categoryId}</strong> <button onClick={() => handleDeletingEntry(category.title)}>Delete</button></p> </span>
            </>
          ))}        
          </>
        }

  return (
    <React.Fragment>
      <Header />
      <div className="mt-5">
        {entryLoaded ? test : null}
      </div>   
    </React.Fragment>
  );
}

export default EditEntry;