import React, { useState, useRef, useContext, useEffect } from "react";
import { collection, addDoc, onSnapshot, setDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import  { db } from '../firebase';
import { v4 } from 'uuid';
import { Header } from '.';

function EditVideo() {

  const inputWidth = {
    width: "30rem"
  }

  const titleRef = useRef();
  const vidRef = useRef();
  const dateRef = useRef();

  const handleSubmit =(e)=>{
    e.preventDefault();
    setDoc(doc(db,'videos', titleRef.current.value ),{
      title: titleRef.current.value,
      link: vidRef.current.value,
      meetingDate: dateRef.current.value,
      id: v4()
    });
    document.getElementById("entryForm").reset();
  }

    return (
      <>
      <Header />
      <div className="">
        <form id="entryForm" className="card border-0 mt-5" onSubmit={handleSubmit}>
          <div className="">
            <input className="form-control" style={inputWidth} type="text" id="title" placeholder="Title" ref={titleRef}/>
            <input className="form-control mt-5" style={inputWidth} type="text" id="link" placeholder="Link" ref={vidRef}/>
            <input className="form-control mt-5" style={inputWidth} type="link" id="meetingDate" placeholder="Meeting Date (mm/dd/yy)" ref={dateRef}/>
            <button className="btn btn-primary mt-5" type='submit'>Upload Video</button>
          </div>
        </form>
      </div>
      </>
    )
}

export default EditVideo;

// db.Timestamp.fromDate() for date to timestamp convert;